
var DocumentsPreview = {
    // video_player_template: _.template($('#tpl_video_player').html()),
    previewData: [],
    videoPreviews: [],

    reset: function(){
        this.previewData = []
        // this.destroyVideos()
    },

    open: function (nodeId) {
        var that = this;
        // var node = this.eltree.tree('getSelectedNode');

        // const nodeId = $(e.currentTarget).data('id')
        const startIndex = _.findIndex(this.previewData, { id: nodeId })

        const debInitVideos = _.debounce(() => {
            // that.initVideos()
            $(window).resize()
        }, 200, {
            'leading': false,
            'trailing': true
        })

        const fancybox = new Fancybox(this.previewData, {
            autoScale: true,
            on: {
                'done': debInitVideos
            }
        });

        if (startIndex > -1)
            fancybox.jumpTo(startIndex)

        // that.initVideos()
        $(window).resize()
    },

    openInline: function (nodeId, parentEl) {
        var that = this;
        // var node = this.eltree.tree('getSelectedNode');

        // const nodeId = $(e.currentTarget).data('id')
        const startIndex = _.findIndex(this.previewData, { id: nodeId })

        // const debInitVideos = _.debounce(() => {
        //     that.initVideos()
        // }, 200, {
        //     'leading': false,
        //     'trailing': true
        // })

        const fancybox = new Fancybox(this.previewData, {
            // parentEl: parentEl,
            closeButton: false//,
            // on: {
            //     'done': debInitVideos
            // }
        });

        if (startIndex > -1)
            fancybox.jumpTo(startIndex)

        // that.initVideos()
    },

    init: function (nodes, downloadUrlRender, publicDownloadUrlRender) {
        const that = this;

        // const root = this.eltree.tree('getTree')
        // const nodes = root['children']

        // Se gia presenti: azzera video
        this.destroyVideos()

        this.previewData = []

        _.each(nodes, function (_node, i) {

            let downloadUri       = !!downloadUrlRender ? downloadUrlRender(_node) : _node['uri']
            let publicDownloadUri = !!publicDownloadUrlRender ? publicDownloadUrlRender(_node) : _node['uri']

            switch (_node['extension']) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    that.previewData.push({
                        id: _node['id'],
                        src: downloadUri,
                        type: 'image',
                        caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>'
                    })
                    break;

                case 'mp4':
                case 'mpg':
                case '3gp':
                case 'mov':
                case 'ogg':
                case 'webm':
                    // let videoPlayerHtml = that.video_player_template({
                    //     id: 'videoPreview-' + _node['id'],
                    //     src: downloadUri,
                    //     mimetype: _node['mimetype']
                    // })

                    // that.videoPreviews.push({
                    //     id: 'videoPreview-' + _node['id'],
                    //     init: false,
                    //     player: null
                    // })

                    that.previewData.push({
                        id: _node['id'],
                        // src: videoPlayerHtml,
                        // type: 'html',
                        src: '/pub/video-frame?video_src=' + encodeURIComponent(downloadUri) + '&video_mimetype=' + encodeURIComponent(_node['mimetype']),
                        type: 'iframe',
                        caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                        thumb: '/file-icon/100/' + _node['extension']
                    })
                    break;

                case 'pdf':
                    that.previewData.push({
                        id: _node['id'],
                        src: '/pdfjs/web/viewer.html?file=' + encodeURIComponent(downloadUri),
                        type: 'pdf',
                        caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                        thumb: '/file-icon/100/' + _node['extension']
                    })
                    break;

                case 'doc':
                case 'docx':
                case 'xls':
                case 'xlsx':
                    that.previewData.push({
                        id: _node['id'],
                        src: '<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(publicDownloadUri) +'" style="width:1024px; height:768px;" frameborder="0" ></iframe>',
                        type: 'html',
                        caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                        thumb: '/file-icon/100/' + _node['extension']
                    })
                    break;

                default:
                    that.previewData.push({
                        id: _node['id'],
                        src: downloadUri,
                        type: 'html',
                        caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                        thumb: '/file-icon/100/' + _node['extension']
                    })
                    break;
            }

        })

    },

    initVideos() {
        const that = this

        if (!this.videoPreviews) {
            this.videoPreviews = []
        }

        if (this.videoPreviews.length > 0) {
            _.each(this.videoPreviews, function (v, i) {
                if (v['id'] ) {
                    let element_found = $('#' + v['id']).length > 0
                    
                    if (!element_found && v['player'] && v['init']) {
                        v['player'].dispose()
                        v['init'] = false
                        v['player'] = null
                    }

                    if (element_found && !v['init']) {
                        // console.log('loaded')
                        v['player'] = videojs(v['id'], {
                            responsive: true
                            // fluid: true,
                            // fill: true
                        })

                        v['init'] = true
                    }
                }

                that.videoPreviews[i]['init'] = v['init']
                that.videoPreviews[i]['player'] = v['player']
            })
        }
    },

    destroyVideos: function () {
        const that = this

        if (!this.videoPreviews) {
            this.videoPreviews = []
        }

        if (this.videoPreviews.length > 0) {
            _.each(this.videoPreviews, function (v, i) {
                if (v['player'] && v['init']) {
                    v['player'].dispose()
                    that.videoPreviews[i]['init'] = false
                    that.videoPreviews[i]['player'] = null
                }
            })
        }

        this.videoPreviews = []
    }
}